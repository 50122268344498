


















































































































































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import { vxm } from '@/store'
import { appendSitePrefix } from '@/utils/routeUtils'
import { formatDateTimeString } from '@/utils/dateUtils'
import PdfViewer from '@/components/PdfViewer.vue'

@Component({
  components: { PdfViewer },
})
export default class List extends Vue {
  private data: any = {}
  private loading = true
  private appendSitePrefix = appendSitePrefix

  private readingPdfs = []
  private tyrePdfs = []

  private tyreReadingImages = []
  private tyreImages = []
  private readingImageCarouselModel = [0, 0, 0, 0]
  private tyreImageCarouselModel = [0, 0, 0, 0]

  private fullImages = []
  private fullImageCarouselModel = 0
  private fullImageDialog = false

  private fullImageTyre = {
    index: null,
    placement: null,
  }

  private tab = -1

  private useRewrittenTyreHotelView = false

  private created() {
    this.loading = true

    this.$axios
      .get('/v4/site/tread-depth-readings/edit/initial-data')
      .then((response) => {
        this.useRewrittenTyreHotelView = response.data.useRewrittenTyreHotelView
      })
      .catch((err) => {
        err.response.data.errors.forEach((v) => {
          vxm.alert.error({
            content: v.message as string,
            title: this.$t('c:common:Error') as string,
          })
        })
      })

    this.$axios
      .get('/v4/site/tread-depth-readings/' + this.$route.params.id)
      .then((response) => {
        const reading = response.data.data

        this.readingPdfs = reading.media.filter((media) => media.type === 'ReportPdf')

        const order = ['ReadingImage', 'Unknown']
        const map = new Map()
        order.forEach((x, i) => map.set(x, i))

        for (let i = 0; i < reading.tyres.length; i++) {
          reading.tyres[i].index = i

          this.tyreReadingImages[i] = reading.tyres[i].media.filter(
            (media) => media.type !== 'TyreImage' && media.type !== 'ReportPdf',
          )
          // Order images based on type, so we have ReadingImage first and then the rest
          this.tyreReadingImages[i].sort((x, y) => map.get(x.type) - map.get(y.type))
          this.tyreImages[i] = reading.tyres[i].media.filter((media) => media.type === 'TyreImage')
          this.tyrePdfs[i] = reading.tyres[i].media.filter((media) => media.type === 'ReportPdf')
        }
        this.data = reading
      })
      .catch((err) => {
        err.response.data.errors.forEach((v) => {
          vxm.alert.error({
            content: v.message as string,
            title: this.$t('c:common:Error') as string,
          })
        })
      })
      .finally(() => {
        this.loading = false
      })
  }

  private getPlacementText(placement) {
    switch (placement) {
      case 'LeftFront':
        return this.$t('Left front')
      case 'RightFront':
        return this.$t('Right front')
      case 'LeftRear':
        return this.$t('Left rear')
      case 'RightRear':
        return this.$t('Right rear')
      default:
        return '' + placement
    }
  }

  private getTyreHeaderClass(tyre) {
    switch (tyre.rating.toLowerCase()) {
      case 'ok':
        return 'ok'
      case 'marginal':
        return 'warning'
      default:
        return 'critical'
    }
  }

  private openReadingFullImageDialog(tyre) {
    this.fullImageCarouselModel = this.readingImageCarouselModel[tyre.index]
    this.fullImages = this.tyreReadingImages[tyre.index]
    this.openFullImageDialog(tyre)
  }

  private openTyreFullImageDialog(tyre) {
    this.fullImageCarouselModel = this.tyreImageCarouselModel[tyre.index]
    this.fullImages = this.tyreImages[tyre.index]
    this.openFullImageDialog(tyre)
  }

  private openFullImageDialog(tyre) {
    this.fullImageDialog = true
    this.fullImageTyre = tyre
  }

  private setTab(index) {
    this.tab = index
  }

  get carUrl() {
    if (!this.data.car || !this.data.car.customer) {
      return ''
    }
    return appendSitePrefix('/' + this.data.car.customer.id + '/customer/show?car_id=' + this.data.car.id)
  }

  get customerUrl() {
    if (!this.data.car || !this.data.car.customer) {
      return ''
    }
    return appendSitePrefix('/' + this.data.car.customer.id + '/customer/show')
  }

  get driverUrl() {
    if (!this.data.car || !this.data.car.driver) {
      return ''
    }
    return appendSitePrefix('/' + this.data.car.driver.id + '/driver/item')
  }

  get tyreHotelUrl() {
    if (this.useRewrittenTyreHotelView) {
      return {
        name: 'TyreHotel/View',
        params: {
          tyreHotelId: this.data.tyreHotelId,
        },
      }
    } else {
      return appendSitePrefix('/' + this.data.tyreHotelId + '/storage/item')
    }
  }

  get readingTimeFormatted() {
    return formatDateTimeString(this.data.readingTime)
  }

  get carDescription() {
    if (!this.data.car) {
      return ''
    }
    const texts = []
    if (this.data.car.brand) {
      texts.push(this.data.car.brand.name)
    }
    if (this.data.car.model) {
      texts.push(this.data.car.model)
    }
    if (this.data.car.year) {
      texts.push(this.data.car.year)
    }
    return texts.join(' ')
  }

  get seasonFormatted() {
    if (!this.data.season) {
      return ''
    }
    return this.$t(this.data.season)
  }
}
